/* Adicione essas regras ao seu arquivo styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom fonts */
@font-face {
  font-family: 'Clarika Pro Geo Bd';
  src: url('../assets/fonts/clarikapro-bold.otf') format('opentype');
}

@font-face {
  font-family: 'Clarika Pro Geo Lt';
  src: url('../assets/fonts/clarikapro-light.otf') format('opentype');
}

@font-face {
  font-family: 'Clarika Pro Geo Rg';
  src: url('../assets/fonts/clarikapro-regular.otf') format('opentype');
}

@font-face {
  font-family: 'Clarika Pro Geo Md';
  src: url('../assets/fonts/clarikapro-medium.otf') format('opentype');
}

/* Header link styles */
.header-link {
  @apply hover:text-red-600 font-bold transition-colors duration-300;
}

.responsive-menu {
  font-size: 14px; /* Ajuste o tamanho da fonte conforme necessário */
}

/* Transparência para o header e o menu expansivo */
.bg-transparent-70 {
  background-color: rgba(255, 255, 255, 0.7); /* Ajuste o valor da transparência conforme necessário */
}

/* Custom styles for About Us */
#about {
  padding-top: 2.5rem; /* py-10 */
  padding-bottom: 2.5rem; /* py-10 */
}

#about p {
  font-size: 1rem; /* Default font size */
  line-height: 1.75rem; /* leading-relaxed */
}

@media (min-width: 768px) {
  #about {
    padding-top: 5rem; /* md:py-20 */
    padding-bottom: 5rem; /* md:py-20 */
  }
  #about p {
    font-size: 1.125rem; /* Increase font size for medium screens */
    line-height: 1.75rem; /* leading-relaxed */
  }
}

@media (min-width: 1024px) {
  #about p {
    font-size: 1.25rem; /* Increase font size for large screens */
    line-height: 2rem; /* leading-relaxed */
  }
}

@media (max-width: 640px) {
  #about .container {
    padding-left: 1rem; /* px-4 */
    padding-right: 1rem; /* px-4 */
  }
  #about p {
    font-size: 0.875rem; /* Reduce font size for small screens */
    line-height: 1.5rem; /* leading-relaxed */
  }
}

/* Button common styles */
.button-common {
  @apply bg-cyan-900 text-white px-3 py-2 rounded-md sm:px-4 sm:py-2 sm:rounded-lg flex items-center transition-all duration-100 hover:bg-red-600;
}

.fixed-button {
  width: auto; /* Ensure button width is auto */
}

/* Input common styles */
.input-common {
  @apply w-full p-2 border rounded font-sans mb-2 sm:mb-2 text-xs sm:text-sm;
}

/* Form common styles */
.form-common {
  @apply bg-white p-3 sm:p-4 rounded-lg shadow-lg mt-2 transition-all duration-300 w-full sm:w-auto;
}

/* Error text styles */
.error-text {
  @apply text-red-600 text-xs sm:text-sm -mt-2 -mb-2; /* Adjust margin-top to reduce spacing */
}

/* Additional media queries for smaller screens */
@media (max-width: 640px) {
  .fixed-button-container {
    width: auto;
  }

  .button-common {
    padding: 0.5rem 1rem; /* Ajuste de padding para telas menores */
    font-size: 0.875rem; /* Fonte menor para dispositivos móveis */
    width: auto; /* Ensure button width is auto */
  }

  .input-common {
    font-size: 0.75rem; /* Fonte menor para dispositivos móveis */
  }

  .form-common {
    padding: 0.75rem; /* Ajuste de padding para telas menores */
  }

  .error-text {
    font-size: 0.75rem; /* Fonte menor para mensagens de erro em dispositivos móveis */
  }
}

@media (max-width: 1024px) {
  .fixed-button-container {
    width: auto;
  }

  .button-common {
    padding: 0.5rem 1rem; /* Ajuste de padding para telas intermediárias */
    font-size: 0.875rem; /* Fonte menor para telas intermediárias */
    width: auto; /* Ensure button width is auto */
  }

  .input-common {
    font-size: 0.75rem; /* Fonte menor para telas intermediárias */
  }

  .form-common {
    padding: 0.75rem; /* Ajuste de padding para telas intermediárias */
  }

  .error-text {
    font-size: 0.75rem; /* Fonte menor para mensagens de erro em telas intermediárias */
  }
}

@media (max-width: 1280px) {
  .fixed-button-container {
    width: auto;
  }

  .button-common {
    padding: 0.5rem 1rem; /* Ajuste de padding para telas menores que 1280px */
    font-size: 0.875rem; /* Fonte menor para telas menores que 1280px */
    width: auto; /* Ensure button width is auto */
  }

  .input-common {
    font-size: 0.75rem; /* Fonte menor para telas menores que 1280px */
    margin-bottom: 0.5rem; /* Margem inferior menor */
  }

  .form-common {
    padding: 0.75rem; /* Ajuste de padding para telas menores que 1280px */
  }

  .error-text {
    font-size: 0.75rem; /* Fonte menor para mensagens de erro em telas menores que 1280px */
  }
}

/* Component: ScrollToTop */

/* Button specific styles */
.scroll-button {
  @apply bg-cyan-900 text-white p-2 sm:p-3 flex items-center transition-all duration-300 hover:bg-red-600 rounded-lg;
}

/* Image positioning */
.flag-image {
  position: relative;
  top: 0.1em; /* Adjust this value as needed */
}

/* Special character styling */
.special-char {
  font-family: sans-serif;
}

/* Dropdown menu styling */
.dropdown-menu {
  @apply absolute left-1/2 transform -translate-x-1/2 mt-2 bg-white rounded-lg shadow-lg z-50;
  display: none;
  transition: all 0.3s ease;
}

.group:hover .dropdown-menu {
  display: block;
}

/* Custom styles for Missions and Values */
.mission-values-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.mission-values-heading {
  text-align: center;
  font-family: 'Clarika Pro Geo Bd';
  color: #164e63;
  margin-bottom: 3rem; /* mb-12 equivalent */
  font-size: 1.875rem; /* 3xl */
  @media (min-width: 768px) {
    font-size: 2.25rem; /* 4xl */
  }
}

.mission-values-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem; /* gap-4 */
  @media (min-width: 768px) {
    gap: 1.5rem; /* md:gap-6 */
  }
  @media (min-width: 1024px) {
    gap: 2rem; /* lg:gap-8 */
  }
}

.mission-values-card {
  max-width: 340px;
  margin: 10px;
  padding: 20px;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.mission-values-card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.mission-values-icon {
  font-size: 2rem;
  color: #164e63;
  margin-bottom: 1rem;
}

.mission-values-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #164e63;
  text-align: center;
  margin-bottom: 1rem;
}

.mission-values-text {
  font-size: 0.875rem;
  color: #4a4a4a;
  text-align: justify;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .mission-values-container {
    padding: 0 10px;
  }
  .mission-values-card {
    max-width: 340px;
    margin: 10px;
  }
  .mission-values-icon {
    font-size: 1.75rem;
  }
  .mission-values-title {
    font-size: 1.125rem;
  }
  .mission-values-text {
    font-size: 0.8125rem;
  }
}

@media (max-width: 768px) {
  .mission-values-container {
    padding: 0 10px;
  }
  .mission-values-card {
    max-width: 320px;
    margin: 10px;
  }
  .mission-values-icon {
    font-size: 1.5rem;
  }
  .mission-values-title {
    font-size: 1rem;
  }
  .mission-values-text {
    font-size: 0.75rem;
  }
}

@media (max-width: 640px) {
  .mission-values-container {
    padding: 0 5px;
  }
  .mission-values-card {
    max-width: 290px;
    margin: 15px;
    padding: 15px;
  }
  .mission-values-icon {
    font-size: 1.25rem;
  }
  .mission-values-title {
    font-size: 0.875rem;
  }
  .mission-values-text {
    font-size: 0.6875rem;
  }
}

/* Custom styles for Services */

.service-card {
  position: relative;
  margin: 10px;
  max-width: 340px;
}

.service-card .overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(22, 78, 99, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 0.75rem;
}

.service-card:hover .overlay {
  opacity: 1;
}

.service-card.modal-open .overlay {
  display: none;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .service-container {
    padding: 0 10px;
  }
  .service-card {
    max-width: 340px;
    margin: 10px;
  }
  .service-icon {
    font-size: 1.75rem;
  }
  .service-title {
    font-size: 1.125rem;
  }
  .service-text {
    font-size: 0.8125rem;
  }
}

@media (max-width: 768px) {
  .service-container {
    padding: 0 10px;
  }
  .service-card {
    max-width: 340px;
    margin: 10px;
  }
  .service-icon {
    font-size: 1.75rem;
  }
  .service-title {
    font-size: 1.125rem;
  }
  .service-text {
    font-size: 0.8125rem;
  }
}

@media (max-width: 640px) {
  .service-container {
    padding: 0 5px;
  }
  .service-card {
    max-width: 320px;
    margin: 5px;
    padding: 15px;
  }
  .service-icon {
    font-size: 1.25rem;
  }
  .service-title {
    font-size: 0.875rem;
  }

  .service-text {
    font-size: 0.6875rem;
  }
}

/* Custom styles for modal background */
.modal-bg {
  position: absolute;
  inset: 0;
  margin: 0;
  border: 2px solid #164e63; /* equivalent to border-cyan-900 in Tailwind */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; /* Prevent the image from repeating */
  border-radius: 0.75rem; /* Adjust the border radius to match the modal */
  z-index: -1;
}

/* Component: Collapsible */
.collapsible {
  @apply mb-4;
}

.collapsible button {
  @apply w-full text-left text-lg font-clarika-pro-geo-lt text-cyan-900 bg-gray-100 hover:bg-gray-200 p-2 rounded-md transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-cyan-900 flex justify-between items-center;
}

.collapsible .content {
  @apply mt-2 p-2 bg-gray-50 rounded-md border-l-4 border-cyan-900 text-justify font-sans;
}

/* Modal Content Styles */
.modal-content {
  @apply p-4 text-gray-700 text-xs sm:text-sm;
}

.modal-content .collapsible {
  @apply mb-4;
}

/* Adjustments for smaller screens */
@media (max-width: 640px) {
  .modal-bg {
    border-width: 1px;
    border-radius: 0.5rem;
  }

  .modal-content {
    @apply p-2 text-xs;
  }

  .collapsible button {
    @apply text-base p-1;
  }

  .collapsible .content {
    @apply mt-1 p-1;
  }
}


/* Background Video Component Styles */

.flag-image {
  @apply w-6 h-4 mx-1;
}

.video-container {
  @apply relative w-full h-screen;
}

.video-element {
  @apply absolute inset-0 object-cover w-full h-full z-0;
}

.slogan-part1, .slogan-part2 {
  @apply absolute inset-x-0 text-white z-10 text-center w-full;
}

.slogan-part1 {
  @apply top-1/2 transform -translate-y-1/2;
}

.slogan-part2 {
  @apply top-1/2 transform translate-y-1/2 mt-4;
}

.slogan-text {
  @apply text-3xl md:text-5xl lg:text-6xl font-bold font-clarika-pro-geo-bd;
}

.slogan-subtext {
  @apply text-2xl md:text-4xl lg:text-5xl font-bold font-clarika-pro-geo-bd;
}

.bottom-slogan {
  @apply absolute inset-x-0 bottom-8 flex flex-row justify-center items-center text-white z-10;
}

.bottom-slogan-text {
  @apply text-lg md:text-xl lg:text-2xl font-bold font-clarika-pro-geo-bd flex flex-row items-center;
}

/* Client logo slider styles */
.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slick-list {
  margin: 0 -5px; /* Ensure a consistent gap between slides */
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px; /* Gap between slides */
  height: 100%;
}

/* Estilo específico para logotipos ampliados */
.logo-enlarge {
  margin-top: -40px; /* Ajuste conforme necessário */
}

